import mainPhoto from './main.jpeg';
import invitation from "./invitation.pdf"
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Se actor de mi primera reunion.
        </p>
        <img src={mainPhoto} className="main-photo" alt="photo" />
        <a
          className="invitation"
          href={invitation}
          download="sabrina's baby shower"
        >
          Sabrina Castrillo Serpone
        </a>
      </header>
    </div>
  );
}

export default App;
